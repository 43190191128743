/*----------------------------------------*/
/*  10. Testimonial CSS
/*----------------------------------------*/

.testimonial-slider {
  .swiper-slide {
    &.swiper-slide-prev {
      opacity: 0.5;
    }
    &.swiper-slide-next {
      opacity: 0.5;
    }
  }
}

.static-testimonial {
  padding: 50px 40px 55px;
  background-color: var(--clr-white);
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  box-shadow: 0px 0px 40px 0px rgba(51, 51, 51, 0.1);
  text-align: center;

  /// here
  ///
  ///

  /* position: relative;
  overflow: hidden; */
  .thumbnail {
    position: relative;
    &::before {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      transition: all 0.5s ease 0s;
      opacity: 0;
      background-color: rgba(18, 146, 238, 0.5);
    }
  }
  /// to here
  // Responsive
  @media #{$large-mobile} {
    padding: 30px 25px 35px;
  }
  .testimonial-image {
    margin-bottom: 25px;
    img {
      border-radius: 50%;
    }
  }
  .testimonial-content {
    margin-bottom: 25px;
    p {
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      color: var(--clr-body);
      margin-bottom: 0;
    }
  }
  .author-info {
    display: inline-block;
    .cite {
      .name {
        display: block;
        font-size: 17px;
        line-height: 1.3;

        margin-bottom: 0;
        font-weight: 400;
        text-transform: uppercase;
        color: var(--clr-primary);
      }
      .position {
        display: block;
        margin-top: 10px;
        font-size: 15px;
        color: var(--clr-body);
      }
    }
  }
}
